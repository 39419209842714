import * as React from "react"

const WhatsappIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.286"
    height="15.411"
    viewBox="0 0 15.286 15.411"
  >
    <g id="whatsapp_1_" data-name="whatsapp (1)" transform="translate(-1.69 0)">
      <path
        id="Trazado_210"
        data-name="Trazado 210"
        d="M8.959.009A7.646,7.646,0,0,0,2.522,11.114l-.811,3.939a.3.3,0,0,0,.36.35l3.86-.914A7.644,7.644,0,1,0,8.959.009Zm4.605,11.866A5.988,5.988,0,0,1,6.67,13l-.538-.268L3.766,13.3l.5-2.418L4,10.36A5.99,5.99,0,0,1,5.1,3.412a5.985,5.985,0,1,1,8.463,8.463Z"
        transform="translate(0 0)"
      />
      <path
        id="Trazado_211"
        data-name="Trazado 211"
        d="M112.141,112.759l-1.48-.425a.552.552,0,0,0-.546.144l-.362.369a.539.539,0,0,1-.586.124,7.9,7.9,0,0,1-2.55-2.248.539.539,0,0,1,.043-.6l.316-.409a.552.552,0,0,0,.068-.561l-.623-1.409a.552.552,0,0,0-.862-.2,2.49,2.49,0,0,0-.963,1.469c-.105,1.037.34,2.345,2.022,3.915,1.943,1.814,3.5,2.053,4.513,1.808a2.49,2.49,0,0,0,1.324-1.154A.552.552,0,0,0,112.141,112.759Z"
        transform="translate(-99.098 -103.458)"
      />
    </g>
  </svg>
)

export default WhatsappIcon
